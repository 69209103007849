
  export const environment = {
    production: true,
    version: '(env.dev)',
    apiUrl: 'https://demo.ppgt.xyz/api',
    apiNgUrl: 'https://api.demo.ppgt.xyz',
    appUrl: 'https://demo.ppgt.xyz',
    appNgUrl: 'https://demo2.ppgt.xyz',
    socketUrl: '',
    socketPath: '/api/socket.io',
    defaultTokenKey: 'token',
    defaultLanguage: 'en',
    enableFloorEditor: false,
    clientShortname: 'DEMO',
    tasksUrl: 'undefined',
    mspIntegrationServiceUrl: 'undefined',
    procoreIntegrationUrl: 'undefined',
    gcpApiKey: 'AIzaSyAZb_iQc3189qwYf-iqKOtLgeX01-UHR40',
    gcpProjectId: 'stage-286013',
    clientLogoUrl: 'undefined'
  };